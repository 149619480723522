<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="status" placeholder="状态" clearable>
          <el-option label="服务中" :value="0"></el-option>
          <el-option label="已结束" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #mec="{ row }">
        <div>
          <p>{{ row.map.mechanism.mechanism_name }}</p>
        </div>
      </template>
      <template #activity_time="{ row }">
        <div>
          <p>开始:{{ row.start_time }}</p>
          <p>结束:{{ row.end_time }}</p>
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag type="info" size="small" v-if="row.status === 0">服务中</el-tag>
          <el-tag type="success" size="small" v-if="row.status === 1"
            >已结束</el-tag
          >
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button v-if="false" type="primary" @click="getList(row)">搜索</el-button>
        </div>
      </template>
    </auto-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "服务名称", value: "title" },
        { name: "购买方", value: "mec", type: "custom" },
        { name: "状态", value: "status", type: "custom" },
        { name: "活动时间", value: "activity_time", type: "custom" },
        { name: "添加时间", value: "create_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      status: 0,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    updateStatus(row,status){
        if(status == 2){
          if(new Date(row.end_time).getTime() < Date.now()){
            this.$message("活动已结束，不能上架")
            return
          }
        }

        this.$post('/user/businessActivityType/update',{id:row.id,status}).then(res=>{
          this.getList(1);
        })
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/productRecord/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status: this.status,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>